require('./bootstrap');
require('./general');
require('./auth');
// require('./nav');
require('./newsletter');
require('./account');
require('./products');
require('./cart');
require('./wishlist');
require('./forms');
require('./checkout');
require('./analytics');
require('./brands');
require('./slick');
require('./menu');
require('./feather-icon');
require('./bootstrap-notify');
require('./script');
require('./modal');
