import Swiper from 'swiper';
import $ from "jquery";

window.alertHtml = (text, type) => {
    if (typeof type == "undefined") {
        type = "danger";
    }

    return `
        <div class="alert alert-${type}">
            <h4 class="m-0 text-center">
                ${text}
            </h4>
        </div>
    `;
};

window._ajax = (endpoint, data, type, isFormData) => {
    if (typeof type == "undefined") {
        type = "POST";
    }

    if (typeof isFormData == "undefined") {
        isFormData = false;
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    let locale = $('html').attr('lang');

    if (locale != 'pt' && locale != 'en') {
        locale = 'pt';
    }

    if (isFormData) {
        return $.ajax({
            url: `/api/${locale}/` + endpoint,
            data: data,
            type: type,
            processData: false,
            contentType: false,
            cache: false,
            dataType: 'json'
        });
    } else {
        return $.ajax({
            url: `/api/${locale}/` + endpoint,
            data: data,
            type: type,
            cache: false,
            dataType: 'json'
        });
    }
};

window.parseLaravelError = xhr => {
    let message = '';

    if (typeof xhr.responseText != "undefined" && xhr.responseText != '') {
        try {
            let response = JSON.parse(xhr.responseText);
            console.log(response);

            message = response.message;
            if (typeof response.errors != "undefined") {
                for (const [key, value] of Object.entries(response.errors)) {
                    if (value.isArray) {
                        for (let index = 0; index < value.length; index++) {
                            const line = value[index];
                            message += '<br>' + line;
                        }
                    } else {
                        message += '<br>' + value;
                    }
                }
            }

        } catch (error) {
            message = 'An error has occurred while trying to process your request.';
        }
    } else {
        message = 'An error has occurred while trying to process your request.';
    }

    return message;
}

window.floatingMessage = function (text, type, link, linkText) {
    if (typeof text == "undefined") {
        text = "";
    }

    if (typeof type == "undefined") {
        type = "success";
    }

    if (typeof link == "undefined") {
        link = "";
    }

    if (typeof linkText == "undefined") {
        linkText = "";
    }

    let linkAppendString = '';
    if (link.trim() != '') {
        linkAppendString = `
            <br>
            <a href='${link}'>
                <b>${linkText == '' ? 'Clique aqui' : linkText}</b>
            </a>
        `;
    }

    $('body').append(`
        <div class="alert alert-${type} alert-dismissible fade show alert-custom" role="alert" id="floatMessage">
            <strong><i class="fas ${type == "success" ? 'fa-check-circle' : 'fa-exclamation-triangle'}" aria-hidden="true"></i></strong>
            ${text}
            ${linkAppendString}
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
    `);

    let message = $('#floatMessage');
    setTimeout(function () {
        message.remove();
    }, 7000);
}

window.__ = function(key, replace = {}) {
    let translation = key.split('.').reduce((t, i) => t[i] || null, window._TRANSLATIONS);

    for (var placeholder in replace) {
        translation = translation.replace(`:${placeholder}`, replace[placeholder]);
    }

    return translation;
}

window.formatAsCurrency = (value) => {
    return new Intl.NumberFormat('pt-PT', {
        style: 'currency',
        currency: 'EUR'
    }).format(value)
};

window.trackFbEvent = (event, data) => {
    if (typeof fbq != 'undefined') {
        try {
            fbq('track', event, data);
        } catch (error) {
            console.log(error);
        }
    } else {
        console.log('FBQ undefined');
    }
};

window.trackGoogleEvent = (event, data) => {
    if (typeof gtag != 'undefined') {
        try {
            gtag('event', event, data);
        } catch (error) {
            console.log(error);
        }
    } else {
        console.log('gtag undefined');
    }
};

document.addEventListener("DOMContentLoaded", function () {
    window.__LOCALE = $('meta[name="locale"]').attr('content');
})
