var bootstrap = require('bootstrap');
var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;
var floatingMessage = window.floatingMessage;
var __ = window.__;
var lang;

function addToCart(product, qtt) {
    if (typeof qtt == "undefined") {
        qtt = 1;
    }

    return new Promise(resolve => {
        ajax('cart/add', {
            product: product,
            qtt: qtt,
        })
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(false);
        });
    });
}

function updateCart(product, qtt) {
    if (typeof qtt == "undefined") {
        qtt = 1;
    }

    return new Promise(resolve => {
        ajax('cart/update', {
            product: product,
            qtt: qtt,
        })
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(false);
        });
    });
}

function removeFromCart(product) {
    return new Promise(resolve => {
        ajax('cart/remove', {
            product: product,
        })
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(false);
        });
    });
}

function listCart() {
    return new Promise(resolve => {
        ajax('cart/list', { })
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(null);
        });
    });
}

function drawCartModal(text) {
    let html =
        "<div class='modal fade' id='modalCart' tabindex='-1' role='dialog' aria-labelledby='modalCartLabel'>" +
        "	<div class='modal-dialog modal-md' role='document'>" +
        "		<div class='modal-content'>" +
        "			<div class='modal-header'>" +
        "				<button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>" +
        "			</div>" +
        "			<div class='modal-body'>" +
        "				<div class='alert alert-success'>" +
        "					<h4 class='text-center'>" +
        "						" + text + "" +
        "					</h4>" +
        "				</div>" +
        "			</div>" +
        "			<div class='modal-footer'>" +
        "				<button type='button' class='btn btn-default' data-bs-dismiss='modal'>" + __('messages.CONTINUAR_A_COMPRAR') + "</button>" +
        "				<a href='/"+ lang +"/shopping-cart'>" +
        "					<button type='button' class='btn btn-solid btn-sm text-white'>" + __('messages.IR_PARA_O_CARRINHO_DE_COMPRAS') + "</button>" +
        "				</a>" +
        "			</div>" +
        "		</div>" +
        "	</div>" +
        "</div>";

    $('body').append(html);

    let modalCartEl = document.getElementById('modalCart');
    let cartModal = new bootstrap.Modal(modalCartEl);
    cartModal.show();

    modalCartEl.addEventListener('hidden.bs.modal', function (e) {
        document.getElementById('modalCart').remove();
    }, false);
}

function updateCartIndicatorAndProducts() {
    const indicator = $('.cart-wrapper .item-count-contain');
    const cartContainer = $('#cart_side ul.cart_product');
    const cartTotalFooter = $('#cart_side ul.cart_total>li>span');
    listCart()
        .then(cart => {
            if (typeof cart != "undefined" && cart != null) {
                indicator.html(cart.qtt);
                cartTotalFooter.html(window.formatAsCurrency(cart.total));

                let html = '';

                for (let item of cart.items) {
                    html += `
                        <li>
                            <div class="media">
                                <a href="/${window.__LOCALE}/buy/${item.product.url_clean}">
                                    <img alt="${item.product.name}" class="me-3" src="${item.product.image}">
                                </a>

                                <div class="media-body">
                                    <a href="/${window.__LOCALE}/buy/${item.product.url_clean}">
                                        <h4>${item.product.name}</h4>
                                    </a>

                                    <h6>
                                        ${item.quantity}x ${window.formatAsCurrency(item.product.salePrice)}
                                    </h6>

                                    <div class="addit-box">
                                        <div class="pro-add text-end pe-3">
                                            <a href="#" class="btn-remove-cart" data-id="${item.product.id}">
                                                <i class="fas fa-trash text-danger"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    `;
                }

                cartContainer.html(html);
            } else {
                indicator.html(0);
                cartContainer.html(`
                    <p class="text-center">Ainda não adicionou produtos ao seu carrinho.</p>
                `);
                cartTotalFooter.html(window.formatAsCurrency(0));
            }
        });
}


document.addEventListener("DOMContentLoaded", function () {
    lang = $('html').attr('lang');

    $('.add-product-to-cart').click(async e => {
        e.preventDefault();

        if (await window.isLogged()) {
            let btn = $(e.currentTarget);
            let product = btn.data('id');

            addToCart(product)
                .then(response => {
                    if (response.status == 'success') {
                        updateCartIndicatorAndProducts();
                        drawCartModal(response.message);

                        // window.__GA3AddToCart(response.product, 1);
                    } else {
                        floatingMessage(response.message, 'danger');
                    }
                });
        } else {
            floatingMessage('Necessita fazer o login para adicionar produtos ao carrinho.', 'warning', '/pt/account', 'Clique aqui para fazer o login');
        }
    });

    if ($('.product-detail-content').length) {
        // Page of product detail
        let btn = $('.product-detail-content #add-to-cart');

        btn.click(async e => {
            e.preventDefault();
            e.stopPropagation();

            if (await window.isLogged()) {
                let qtt = $('.product-detail-content #quantity');

                addToCart(btn.data('id'), qtt.val())
                    .then(response => {
                        if (response.status == 'success') {
                            updateCartIndicatorAndProducts();
                            drawCartModal(response.message);

                            window.__GA3AddToCart(response.product, qtt.val());
                        } else {
                            floatingMessage(response.message, 'danger');
                        }
                    });
            } else {
                floatingMessage('Necessita fazer o login para adicionar produtos ao carrinho.', 'warning', '/pt/account', 'Clique aqui para fazer o login');
            }
        });
    }

    let updateQtt = $('.quantity-wrapper .item-quantity');
    if (updateQtt.length) {
        updateQtt.change(e => {
            let input = $(e.currentTarget);
            let product = input.data('id');
            let qtt = input.val();

            updateCart(product, qtt)
                .then(r => {
                    window.location.reload();
                })
        });
    }


    $(document).on('click', '.btn-remove-cart', e => {
        e.preventDefault();

        let btn = $(e.currentTarget);
        let product = btn.data('id');

        removeFromCart(product)
            .then(r => {
                window.location.reload();
            });
    });


    updateCartIndicatorAndProducts();
}, false);
