var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;
var floatingMessage = window.floatingMessage;
var __ = window.__;

function getShippingOptions(country, district) {
    retrieveShipping(country, district)
        .then(drawShippingOptions);
}

function retrieveShipping(country, district) {
    return new Promise(resolve => {
        let data = {
            country: country,
            district: district,
        };

        ajax('checkout/shipping', data)
            .done(resolve)
            .fail((x, r, t) => {
                console.log(x, r, t);

                let messageString = parseLaravelError(x);
                resolve({ error: messageString });
            });
    });
}

function drawShippingOptions(options) {
    let container = $('.result-metodo-envio');

    if (typeof options.error != "undefined" && options.error != '') {
        container.html(options.error);
    } else {
        let html = '';

        if (options.length > 0) {
            for (let option of options) {
                html += `
                    <div class='radio-morada clearfix'>
                        <div class='input d-inline'>
                            <input required type='radio' name='expedicao' value='${option.id}' data-valor='${option.price}'>
                        </div>
                        <div class='texto d-inline'>
                            <b>${option.title} - ${option.priceHuman}</b>
                            <br><br>
                            ${option.summary}
                        </div>
                    </div>
                `;
            }
        } else {
            html = `
                <p>Não foi possível encontrar um método de envio disponível para o país seleccionado.</p>
            `;
        }

        container.html(html);
    }
}

function getPaymentMethods(country) {
    retrievePayments(country)
        .then(drawPaymentMethods);
}

function retrievePayments(country) {
    return new Promise(resolve => {
        let data = {
            country: country,
        };

        ajax('checkout/payments', data)
            .done(resolve)
            .fail((x, r, t) => {
                console.log(x, r, t);

                let messageString = parseLaravelError(x);
                resolve({ error: messageString });
            });
    });
}

function drawPaymentMethods(methods) {
    let container = $('.result-metodo-pagamento');

    if (typeof methods.error != "undefined" && methods.error != '') {
        container.html(methods.error);
    } else {
        let html = '';

        if (methods.length > 0) {
            for (let option of methods) {
                html += `
                    <div class='radio-morada clearfix'>
                        <div class='input d-inline'>
                            <input type='radio' name='pagamento' value='${option.id}' data-cod='${option.code}' required>
                        </div>

                        <div class='imagem d-inline'>
                            <img src='${option.image}' alt='${option.name}' width='60'>
                        </div>

                        <div class='texto d-inline'>
                            <b>${option.name}</b>
                            <br>
                            ${option.summary}
                        </div>
                    </div>
                `;
            }
        } else {
            html = `
                <p>Não foi possível encontrar um método de pagamento disponível para o país seleccionado.</p>
            `;
        }

        container.html(html);
    }
}

function updateTotalPriceAndShipping(id, value) {
    let data = {
        id: id,
        value: value,
    };

    ajax('checkout/update-price', data)
        .done(response => {
            if (response.success) {
                $('.form-finish #shippingPrice').html(response.shippingPretty);
                $('.form-finish #totalPrice').html('<strong><u>' + response.totalPretty + '</u></strong>');

                if ($('.form-finish #shippingPriceConv').length) {
                    $('.form-finish #shippingPriceConv').html(response.shippingPrettyConv);
                    $('.form-finish #totalPriceConv').html('<strong><u>' + response.totalPrettyConv + '</u></strong>');
                }
            }
        })
        .fail((x, r, t) => {
            console.log(x, r, t);

            let messageString = parseLaravelError(x);
        });
}

function validateVoucher() {
    let form = $('.formVoucherCarrinho');

    if (form.length) {
        form.submit(e => {
            e.preventDefault();

            let input = form.find('#cod-promocional');
            let data = {
                voucher: input.val(),
            };

            ajax('checkout/voucher', data)
                .done(response => {
                    if (response.success) {
                        window.location.reload();
                    } else {
                        floatingMessage(response.message, 'danger');
                    }
                })
                .fail((x, r, t) => {
                    console.log(x, r, t);

                    let messageString = parseLaravelError(x);
                });
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    $('.radio-morada input[type="radio"][name="entrega"]').change( event => {

        let input = $('.radio-morada input[type="radio"][name="entrega"]:checked');
        let id = input.val();
        let country = input.attr("data-pais");
        let district = input.attr("data-distrito");

        getShippingOptions(country, district);
        getPaymentMethods(country);
    });

    $('body').on('click', '.radio-morada', function (e) {
        let box = $(this);
        let checkbox = box.find('input[type="radio"]');

        if (checkbox.length) {
            checkbox.prop("checked", true);
            checkbox.trigger("change");
        }
    });

    $('.form-finish select#pais').change(event => {
        let selectCountry = $(event.currentTarget);
        let selectDistrict = $('.form-finish select#distrito');
        let distritoLabel = $('label#lbl-distrito');

        let country = selectCountry.val();
        let html = '';

        if (country != "") {
            /* ================= */
            /* Get distrito rule */
            if (country == "PRT" || country == "BRA" || country == "ESP") {
                selectDistrict.html("<option value=''>Loading...</option>");

                if (country == "PRT" || country == "ESP") {
                    distritoLabel.html(__('messages.DISTRICT') + ' *');
                    html = "<option value=''>" + __('messages.ENTER_DISTRICT') + " *</option>";
                } else {
                    distritoLabel.html('Estado *');
                    html = "<option value=''>Seleccione o estado *</option>";
                }

                selectDistrict.prop({
                    disabled: false,
                    readonly: false,
                    required: true
                });

                selectDistrict.removeAttr('disabled');
                selectDistrict.removeAttr('readonly');

                ajax('address/districts', {
                        country: country
                    })
                    .done(districts => {
                        if (districts != null && districts.length > 0) {
                            let html = '<option value="">' + __('messages.SELECCIONE') + '</option>';

                            for (let index = 0; index < districts.length; index++) {
                                const district = districts[index];
                                html += `<option value="${district.idDistrito}">${district.nomeDistrito}</option>`;
                            }

                            selectDistrict.html(html);
                            selectDistrict.prop("required", true);

                            selectDistrict.prop("disabled", false);
                            selectDistrict.removeAttr('disabled');

                            selectDistrict.prop("readonly", false);
                            selectDistrict.removeAttr('readonly');
                        } else {
                            selectDistrict.html('');
                            selectDistrict.prop("required", false);
                            selectDistrict.removeAttr('required');

                            selectDistrict.prop("disabled", true);
                            selectDistrict.prop("readonly", true);
                        }
                    })
                    .fail((xhr, r, t) => {
                        console.log(xhr, r, t);
                    });
            } else {
                selectDistrict.prop({
                    disabled: true,
                    readonly: true,
                    required: false
                });

                selectDistrict.removeAttr('required');
                selectDistrict.html("<option value=''></option>");
            }

            /* ================= */
            /* Get CPF rule */
            let cpfContainer = $('#cpf-container');
            if (country == "BRA") {
                cpfContainer.show();
                cpfContainer.find('input').prop({
                    require: true,
                    readonly: false,
                    disabled: false
                });

                let inputCpf = cpfContainer.find('input');
                inputCpf.change(function () {
                    var len = $(this).val().length;
                    console.log(len);

                    if (len <= 11) {
                        $(this).mask('999.999.999-99');
                    } else if (len > 11) {
                        $(this).mask('99.999.999/9999-99');
                    }
                });

                inputCpf.focus(function () {
                    var val = $(this).val().replaceAll("-", "").replaceAll(".", "").replaceAll("/", ""); //remove the dashes from the old masked value
                    $(this).mask("999999999999999999"); //set to maximum length
                    $(this).val(val);
                    var len = val.length;
                    console.log(len);
                });
            } else {
                cpfContainer.hide();
                cpfContainer.find('input').prop({
                    require: false,
                    readonly: true,
                    disabled: false
                });
            }
        } else {
            selectDistrict.html("<option value=''>" + __('messages.ENTER_DISTRICT') + "</option>");
        }
    });

    $('.form-finish select#pais, .form-finish select#distrito').change(event => {
        let selectCountry = $('.form-finish select#pais');
        let selectDistrict = $('.form-finish select#distrito');
        let country = selectCountry.val();
        let district = selectDistrict.val();

        getShippingOptions(country, district);
        getPaymentMethods(country);
    });


    $('body').on('change', '.form-finish input:radio[name=expedicao]', e => {
        let input = $(e.currentTarget);
        updateTotalPriceAndShipping(input.val(), parseFloat(input.attr("data-valor")));
    });

    validateVoucher();
}, false);
