var axios;
var floatingMessage;

async function formLogin() {
    const form = $('form.form-login-ajax');

    if (form.length) {
        form.submit(async e => {
            e.preventDefault();

            const email = form.find('input[type="email"][name="email"]').val();
            const password = form.find('input[type="password"][name="password"]').val();

            if (await authenticateUser(email, password)) {
                window.location.href = '/';
            } else {
                floatingMessage('E-mail e/ou password inválidos.', 'danger');
            }
        });
    }
}

window.setSanctumToken = (token) => {
    localStorage.setItem('sanctumApiToken', token);
}

window.getSanctumToken = () => {
    return localStorage.getItem('sanctumApiToken');
}

window.isLogged = async (usingToken) => {
    if (typeof usingToken == 'undefined') {
        usingToken = false;
    }

    return new Promise(async resolve => {
        if (usingToken) {
            let token = window.getSanctumToken();

            if (token != null && token.trim() != '') {
                resolve(true);
            }

            resolve(false);
        } else {
            const user = await window.getUser();

            if (user != null && typeof user.id != 'undefined' && user.id > 0) {
                resolve(true);
            } else {
                resolve(false);
            }
        }
    });
}

async function authenticateUser(email, password) {
    return new Promise(resolve => {
        window.axios.get('/sanctum/csrf-cookie').then(async response => {
            window.axios.post(`/api/${window.__LOCALE}/login`, {
                email: email,
                password: password,
            }).then(async reply => {
                window.setSanctumToken(reply.data.data.token);

                const user = await window.getUser();

                if (user != null && typeof user.id != 'undefined') {
                    resolve(true);
                } else {
                    resolve(false);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve(false);
            })
        })
        .catch((error) => {
            console.log(error);
            resolve(false);
        });
    });
}

window.getUser = async () => {
    return new Promise(resolve => {
        window.axios.get(`/api/${window.__LOCALE}/user`).then(response => {
            resolve(response.data);
        })
        .catch((error) => {
            console.log(error);
            resolve(null);
        });
    });
}

window.getAxiosAuthHeader = () => {
    return {
        headers: {
            Authorization: 'Bearer ' + window.getSanctumToken()
        }
    };
}

document.addEventListener("DOMContentLoaded", function () {
    axios = window.axios;
    floatingMessage = window.floatingMessage;

    formLogin();
});
