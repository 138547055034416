var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;
var floatingMessage = window.floatingMessage;
var __ = window.__;

async function listWishlist() {
    return new Promise(resolve => {
        ajax('wishlist/list', {})
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(null);
        });
    });
}

async function updateWishlistIndicatorAndProducts() {
    const indicator = $('.wishlist-wrapper .item-count-contain');
    const wishlistContainer = $('#wishlist_side ul.cart_product');
    const wishlistTotalFooter = $('#wishlist_side ul.cart_total>li>span');
    let wishlist = await listWishlist();

    if (typeof wishlist != "undefined" && wishlist != null) {
        indicator.html(wishlist.length);

        let html = '';
        let total = 0;

        for (let item of wishlist) {
            html += `
                <li>
                    <div class="media">
                        <a href="/${window.__LOCALE}/buy/${item.product.url_clean}">
                            <img alt="${item.product.name}" class="me-3" src="${item.product.image}">
                        </a>

                        <div class="media-body">
                            <a href="/${window.__LOCALE}/buy/${item.product.url_clean}">
                                <h4>${item.product.name}</h4>
                            </a>

                            <h6>
                                ${window.formatAsCurrency(item.product.salePrice)}
                            </h6>
                        </div>
                    </div>
                </li>
            `;
            total += item.product.salePrice;
        }

        wishlistContainer.html(html);
        wishlistTotalFooter.html(window.formatAsCurrency(total));

    } else {
        indicator.html(0);
        wishlistContainer.html(`
            <p class="text-center">Ainda não adicionou produtos a sua wishlist.</p>
        `);
        wishlistTotalFooter.html(window.formatAsCurrency(0));
    }

}

async function addToWishlist(product) {
    return new Promise(resolve => {
        ajax('wishlist/add', {
            product: product
        })
            .done(resolve)
            .fail((x, r, t) => {
                console.log(x, r, t);
                resolve(false);
            });
    });
}

async function removeFromWishlist(id) {
    return new Promise(resolve => {
        ajax('wishlist/remove', {
        id: id
    })
        .done(resolve)
        .fail((x, r, t) => {
            console.log(x, r, t);
            resolve(false);
        });
    });
}

document.addEventListener("DOMContentLoaded", function () {
    updateWishlistIndicatorAndProducts();

    // Add listener on document to listen for events on elements that changed classes
    $(document).on('click','.add-product-to-wishlist', async function(e) {
        e.preventDefault();

        let btn = $(e.currentTarget);
        let cpr = btn.attr('data-id');
        let source = btn.attr('data-source');

        let response = await addToWishlist(cpr);

        if (response.status == 'success') {
            // Attach wishlist id and classes for wishlist removal
            btn.addClass('remove-product-from-wishlist');
            btn.removeClass('add-product-to-wishlist');
            btn.attr('data-tippy-content', 'Remover da Wishlist');

            floatingMessage('O produto foi adicionado à sua wishlist', 'success');

            await updateWishlistIndicatorAndProducts();
        } else {
            floatingMessage('É preciso fazer o login para adicionar à sua wishlist', 'danger');
        }
    });

    $(document).on('click','.remove-product-from-wishlist', async function(e) {
        e.preventDefault();

        let btn = $(e.currentTarget);
        let id = btn.attr('data-id');
        let action = btn.attr('data-action');
        let source = btn.attr('data-source');

        let response = await removeFromWishlist(id);

        if (response.status == 'success') {
            // Remove element from wishlist account
            if(action === 'remove') {
                $('#wishlist_'+id).remove();
            } else {
                // Toggle data and classes for wishlist add
                btn.addClass('add-product-to-wishlist');
                btn.removeClass('remove-product-from-wishlist');
                btn.attr('data-tippy-content', 'Adicionar à Wishlist');
            }

            floatingMessage(response.message, 'success');
            await updateWishlistIndicatorAndProducts();
        } else {
            floatingMessage(response.message, 'danger');
        }
    });
}, false);
