const { isNull } = require("lodash");

var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;

function dashboardAjax() {
    if ($('form.customer-user-data').length) {
        $('form.customer-user-data').submit(e => {
            e.preventDefault();

            let form = $(e.currentTarget);
            let formData = new FormData(form[0]);
            let message = $('.message-user-data');

            message.html('');

            ajax('account/update-personal-info', formData, "POST", true)
                .done(r => {
                    if (r.status == 'success') {
                        form.fadeOut();
                        message.html(alertHtml(r.message, 'success'));
                    } else {
                        message.html(alertHtml(r.message, 'danger'));
                    }
                })
                .fail((xhr, r, t) => {
                    console.log("Error while changing user data: ");
                    console.log(xhr, r, t);

                    let messageString = parseLaravelError(xhr);

                    message.html(alertHtml(messageString, 'danger'));
                });
        });
    }

    if ($('form.customer-change-password').length) {
        $('form.customer-change-password').submit(e => {
            e.preventDefault();

            let form = $(e.currentTarget);
            let formData = new FormData(form[0]);
            let message = $('.message-change-password');

            message.html('');

            if (formData.get('newPassword') == formData.get('confNewPassword')) {
                ajax('account/change-password', formData, "POST", true)
                    .done(r => {
                        if (r.status == 'success') {
                            form.fadeOut();
                            message.html(alertHtml(r.message, 'success'));
                        } else {
                            message.html(alertHtml(r.message, 'danger'));
                        }
                    })
                    .fail((xhr, r, t) => {
                        console.log("Error while changing password: ");
                        console.log(xhr, r, t);

                        let messageString = parseLaravelError(xhr);

                        message.html(alertHtml(messageString, 'danger'));
                    });
            } else {
                message.html(alertHtml('As passwords inseridas não coindidem', 'danger'));
            }
        });
    }

    $('#show-encomenda-original').click(e => {
        e.preventDefault();
        $('#encomenda_original').toggle();
    });
}

function addresses() {
    let addAddressModal = $('#myModalMorada');
    if (addAddressModal.length) {
        let countrySelect = addAddressModal.find('select#country_id');
        let districtSelect = addAddressModal.find('select#district_id');

        countrySelect.change(e => {
            let val = countrySelect.val();

            console.log(val);

            ajax('address/districts', { country: val })
                .done(districts => {
                    if (isNull(districts) == false && districts.length > 0) {
                        let html = '';

                        for (let index = 0; index < districts.length; index++) {
                            const district = districts[index];
                            html += `<option value="${district.id}">${district.name}</option>`;
                        }

                        districtSelect.html(html);
                        districtSelect.prop("required", true);

                        districtSelect.prop("disabled", false);
                        districtSelect.removeAttr('disabled');

                        districtSelect.prop("readonly", false);
                        districtSelect.removeAttr('readonly');
                    } else {
                        districtSelect.html('');
                        districtSelect.prop("required", false);
                        districtSelect.removeAttr('required');

                        districtSelect.prop("disabled", true);
                        districtSelect.prop("readonly", true);
                    }
                })
                .fail((xhr, r, t) => {
                    console.log(xhr, r, t);
                })
        });

        addAddressModal.find('.btn-add-morada').click(e => {
            e.preventDefault();

            let submit = addAddressModal.find('#submit');
            submit.click();
        });

        addAddressModal.find('form').submit(e => {
            e.preventDefault();

            let form = $(e.currentTarget);
            let formData = new FormData(form[0]);
            let message = $('.message-add-address');

            message.html('');

            ajax('address/create', formData, "POST", true)
                .done(r => {
                    if (typeof r.id != 'undefined' && r.id > 0) {
                        message.html(alertHtml('Morada adicionada com sucesso', 'success'));

                        setTimeout(() => {
                            window.location.reload(true);
                        }, 2000);
                    } else {
                        message.html(alertHtml('Ocorreu um erro ao tentar adicionar a morada.', 'danger'));
                    }
                })
                .fail((xhr, r, t) => {
                    console.log("Error while adding address: ");
                    console.log(xhr, r, t);

                    let messageString = parseLaravelError(xhr);

                    message.html(alertHtml(messageString, 'danger'));
                });
        });
    }

    let btnRemoveAddress = $('.btn-remove-address');
    if (btnRemoveAddress.length) {
        btnRemoveAddress.click(e => {
            e.preventDefault;

            let btn = $(e.currentTarget);
            let id = btn.data('id');

            if (typeof id != "undefined") {
                ajax('address/remove', { id : id })
                    .done(r => {
                        if (r.status == 'success') {
                            window.location.reload();
                        } else {
                            alert(r.message);
                        }
                    })
                    .fail((x, r, t) => {
                        console.log("Error while removing address: ");
                        console.log(x, r, t);

                        let messageString = parseLaravelError(x);

                        alert(messageString);
                    });
            }
        })
    }
}

document.addEventListener("DOMContentLoaded", function() {
    dashboardAjax();
    addresses();
});
