var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;

function reloadPage() {
    if (location.href.indexOf("filter-applied") === -1) {
        if (location.href.indexOf("?") === -1) {
            window.location = location.href += "?filter-applied=true";
        } else {
            window.location = location.href += "&filter-applied=true";
        }
    } else {
        window.location.reload();
    }
}


function catalog() {
    let minPriceSelector = $('.products-page #filter-min-price');
    let maxPriceSelector = $('.products-page #filter-max-price');
    let brandSelector = $('.products-page .filter-brand');
    let perPageSelector = $('.products-page #display-per-page');
    let orderBySelector = $('.products-page #order-by');

    if (minPriceSelector.length) {
        minPriceSelector.change(e => {
            let val = minPriceSelector.val();

            ajax('products/set-filter', {
                filter: 'search.minPrice',
                value: val,
            })
            .done(r => {
                reloadPage();
            })
        });
    }

    if (maxPriceSelector.length) {
        maxPriceSelector.change(e => {
            let val = maxPriceSelector.val();

            ajax('products/set-filter', {
                filter: 'search.maxPrice',
                value: val,
            })
            .done(r => {
                reloadPage();
            })
        });
    }

    if (brandSelector.length) {
        brandSelector.change(e => {
            let val = $('.products-page .filter-brand:checked').val();

            ajax('products/set-filter', {
                filter: 'search.brand',
                value: val,
            })
            .done(r => {
                reloadPage();
            })
        });
    }

    if (perPageSelector.length) {
        perPageSelector.change(e => {
            let val = perPageSelector.val();

            ajax('products/set-filter', {
                filter: 'search.limit',
                value: val,
            })
            .done(r => {
                reloadPage();
            })
        });
    }

    if (orderBySelector.length) {
        orderBySelector.change(e => {
            let val = orderBySelector.val();
            let order = '';
            let orderBy = '';

            switch (val) {
                case 'featured':
                    order = 'name_pt';
                    orderBy = 'ASC';
                    break;

                case 'price-asc':
                    order = 'price';
                    orderBy = 'ASC';
                    break;

                case 'price-desc':
                    order = 'price';
                    orderBy = 'DESC';
                    break;

                case 'promotion':
                    order = 'promotion';
                    orderBy = 'DESC';
                    break;

                case 'new':
                    order = 'new';
                    orderBy = 'DESC';
                    break;

                default:
                    break;
            }

            ajax('products/set-filter', {
                filter: 'search.order',
                value: order,
            })
            .done(r => {
                ajax('products/set-filter', {
                    filter: 'search.order_by',
                    value: orderBy,
                })
                .done(r => {
                    reloadPage();
                });
            })
        });
    }
}

function productDetail() {
    let btnFormAvailability = $('.btn-aviso-disponibilidade');
    let formAvailability = $('.form-availability');

    if (btnFormAvailability.length) {
        btnFormAvailability.click(e => {
            e.preventDefault();

            formAvailability.toggle('slow');
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    catalog();
    productDetail();
}, false);
