var ajax = window._ajax;
var alertHtml = window.alertHtml;

function isGtagLoaded() {
    if (/* typeof dataLayer != "undefined" &&  */typeof gtag != 'undefined') {
        console.log('gtag is loaded');
        return true;
    }

    console.log('gtag is NOT loaded');

    return false;
}

window.__GA3AddToCart = (product, qtt) => {
    if (isGtagLoaded()) {
        /* dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {
                    'products': [{
                        'name': product.name,
                        'id': product.cpr,
                        'price': product.salePrice,
                        'brand': '',
                        'category': '',
                        'variant': '',
                        'quantity': qtt
                    }]
                }
            }
        }); */

        gtag('event', 'add_to_cart', {
            "items": [{
                "id": product.cpr,
                "name": product.name,
                "list_name": "",
                "brand": "",
                "category": "",
                "variant": "",
                "list_position": 1,
                "quantity": qtt,
                "price": product.salePrice
            }]
        });
    }
};

window.__GA3StartCheckout = (products) => {
    if (isGtagLoaded()) {
        /* dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'event': 'checkout',
            'ecommerce': {
                'checkout': {
                    'actionField': {
                        'step': 1
                    },
                    'products': products
                }
            }
        }); */

        gtag('event', 'begin_checkout', {
            "items": products,
            "coupon": ""
        });
    }
};

window.__GA3Purchase = (order) => {
    if (isGtagLoaded()) {
        /* dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'ecommerce': {
                'currencyCode': 'EUR',
                'purchase': {
                    'actionField': {
                        'id': order.nid,
                        'affiliation': 'Cosmetic2Go',
                        'currency': 'EUR',
                        'revenue': order.total_final,
                        'tax': 0,
                        'shipping': order.expedicao_valor,
                        'coupon': ''
                    },
                    'products': order.products
                }
            }
        }); */

        gtag('event', 'purchase', {
            "transaction_id": order.nid,
            "affiliation": "Cosmetic2Go",
            "value": order.total_final,
            "currency": "EUR",
            "tax": 0,
            "shipping": order.expedicao_valor,
            "items": order.products
        });
    }
};

window.__GA3ProductDetail = (product) => {
    if (isGtagLoaded()) {
        /* dataLayer.push({ ecommerce: null });
        dataLayer.push({
            'ecommerce': {
                'detail': {
                    'products': [{
                        'name': product.name,
                        'id': product.cpr,
                        'price': product.salePrice,
                        'brand': '',
                        'category': '',
                        'variant': ''
                    }]
                }
            }
        }); */

        gtag('event', 'view_item', {
            "items": [{
                "id": product.cpr,
                "name": product.name,
                "list_name": "Product detail",
                "brand": "",
                "category": "",
                "variant": "",
                "list_position": 1,
                "quantity": 1,
                "price": product.salePrice
            }]
        });


    }
};
