const { isNull } = require("lodash");

var ajax = window._ajax;
var alertHtml = window.alertHtml;

function helpForm() {
    if ($('#form-help').length) {
        $('#form-help').submit(e => {
            e.preventDefault();

            let form = $(e.currentTarget);
            let button = form.find('button');
            let buttonOriginalHtml = button.html();
            let formData = new FormData(form[0]);
            let message = $('.message-help');

            message.html('');

            if (button.prop('disabled') == false) {
                button.html(`<i class="fas fa-spinner fa-spin"></i>`);
                button.prop('disabled', true);

                ajax('form/help', formData, "POST", true)
                    .done(r => {
                        if (r.success) {
                            form.fadeOut();
                            message.html(alertHtml(r.message, 'success'));
                            button.html(buttonOriginalHtml);
                            button.prop('disabled', false);
                        } else {
                            message.html(alertHtml(r.message, 'danger'));
                            button.html(buttonOriginalHtml);
                            button.prop('disabled', false);
                        }
                    })
                    .fail((x, r, t) => {
                        console.log("Error while submiting help form: ");
                        console.log(x, r, t);
                        message.html(alertHtml('An error has occurred while trying to process your request.', 'danger'));
                        button.html(buttonOriginalHtml);
                        button.prop('disabled', false);
                    });
            }
        });
    }
}

function contactsForm() {
    if ($('#form-contacts').length) {
        $('#form-contacts').submit(e => {
            e.preventDefault();

            let form = $(e.currentTarget);
            let button = form.find('button');
            let buttonOriginalHtml = button.html();
            let formData = new FormData(form[0]);
            let message = $('.message-contacts');

            message.html('');

            if (button.prop('disabled') == false) {
                button.html(`<i class="fas fa-spinner fa-spin"></i>`);
                button.prop('disabled', true);

                ajax('form/contacts', formData, "POST", true)
                    .done(r => {
                        if (r.success) {
                            form.fadeOut();
                            message.html(alertHtml(r.message, 'success'));
                            button.html(buttonOriginalHtml);
                            button.prop('disabled', false);
                        } else {
                            message.html(alertHtml(r.message, 'danger'));
                            button.html(buttonOriginalHtml);
                            button.prop('disabled', false);
                        }
                    })
                    .fail((x, r, t) => {
                        console.log("Error while submiting contacts form: ");
                        console.log(x, r, t);
                        message.html(alertHtml('An error has occurred while trying to process your request.', 'danger'));
                        button.html(buttonOriginalHtml);
                        button.prop('disabled', false);
                    });
            }
        });
    }
}

document.addEventListener("DOMContentLoaded", function() {
    helpForm();
    contactsForm();
});
