var ajax;
var alertHtml;
var parseLaravelError;
var floatingMessage;
var __;

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }

    return false;
}

function checkIfCookieExists(cCookieName) {
    if (document.cookie.indexOf(cCookieName.trim() + "=") == -1) {
        return false;
    } else {
        return true;
    }
}

function showBox() {
    $('.box-newsletter').fadeIn("slow");

    $('.btn-close-news').click(function (event) {
        $('.box-newsletter').fadeOut("slow");
        setCookie("NotShowNewsletter", true, 7);
    });

    $('.box-newsletter > .body a').click(function (event) {
        $('.btn-close-news').click();
    });

    newsletterFormSubmit();
}

function newsletterFormSubmit() {
    $('form.form-newsletter').on('submit', (event) => {
        event.preventDefault();

        let form = $(event.currentTarget);
        let formData = new FormData(form[0]);
        let button = form.find('button');
        let originalHtml = button.html();
        let messageDiv = $('.message-newsletter');

        messageDiv.html("");
        button.html(`<i class="fas fa-spinner fa-spin"></i>`);

        ajax('newsletter/sign-up', formData, 'POST', true)
            .done(function (response) {
                if (response.success == true) {
                    let message = response.message || __('messages.NEWSLETTER_SUCCESS');
                    let html =`
                        <div class='alert alert-success'>
                            <h4 class='text-center'>${message}</h4>
                        </div>
                    `;

                    form.fadeOut("fast");
                    messageDiv.html(html);
                    setCookie("AlreadySubscribe", 90);
                } else {
                    let message = response.message || __('messages.NEWSLETTER_ERROR');

                    let html = `
                        <div class='alert alert-danger'>
                            <h4 class='text-center'>${message}</h4>
                        </div>
                    `;

                    messageDiv.html(html);
                    button.html(originalHtml);
                }

            })
            .fail(function (xhr, errorThrown, textStatus) {
                console.log(xhr, errorThrown, textStatus);

                let message = __('messages.NEWSLETTER_ERROR');

                let html = `
                    <div class='alert alert-danger'>
                        <h4 class='text-center'>${message}</h4>
                    </div>
                `;

                messageDiv.html(html);
                button.html(originalHtml);
            });
    });
}

document.addEventListener('DOMContentLoaded', function() {
    ajax = window._ajax;
    alertHtml = window.alertHtml;
    parseLaravelError = window.parseLaravelError;
    floatingMessage = window.floatingMessage;
    __ = window.__;
    newsletterFormSubmit();
}, false);

window.addEventListener("load", function () {
/*     var begin = Date.now();
    var nLimitSeconds = 20;
    var oSetInterval = setInterval(function () {

        ajax('newsletter/count', {})
            .done(function (response) {
                if (response.count >= nLimitSeconds) {
                    var end = Date.now();
                    var timeSpent = (end - begin) / 1000 + "secs";
                    clearInterval(oSetInterval);

                    if (checkIfCookieExists("NotShowNewsletter") == false && checkIfCookieExists( "AlreadySubscribe") == false) {
                        showBox();
                    }
                    console.log(timeSpent);
                } else {
                    console.log("Newsletter box timer: ", response.count);
                }
            })
            .fail(function (xhr, errorThrown, textStatus) {
                console.log(xhr, errorThrown, textStatus);
            });
    }, 1000); */
}, false);
