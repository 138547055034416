var ajax = window._ajax;
var alertHtml = window.alertHtml;
var parseLaravelError = window.parseLaravelError;
var floatingMessage = window.floatingMessage;
var __ = window.__;
var lang;

function alphabeticalBrands() {
    let linkLetterBrands = $('.alphabetical-brands .pagination a');
    if (linkLetterBrands.length) {
        linkLetterBrands.click(e => {
            e.preventDefault();

            let letterLink = $(e.currentTarget);
            let letter = letterLink.attr('href').replace('#', '');
            let row = $('.row.row-' + letter);

            if (row.length) {
                let top = row.offset().top - 30;

                jQuery("html, body").animate({
                    scrollTop: top
                }, 700);

                /**
                 * Class
                 */
                linkLetterBrands.each((i, el) => {
                    $(el).closest('li').removeClass('active');
                });
                letterLink.closest('li').addClass('active');
            }
        });
    }
}

function searchBrands() {
    /* Search */
    let searchInput = $('.brands-page input#pesquisar-marca');
    let autocompleteSuggestions = $('.autocomplete-loading');
    if (searchInput.length) {
        searchInput.autocomplete({
            serviceUrl: '/api/brands/search',
            type: 'POST',
            dataType: 'json',
            maxHeight: 450,

            formatResult: (suggestion, currentValue) => {
                let html = `
                    <div class="row">
                        <div class="col-xs-2 col-md-3">
                            <img src="${suggestion.data.image}" alt="${suggestion.value}" class="img-fluid d-block mx-auto" style="max-height: 50px;">
                        </div>

                        <div class="col-xs-8 col-md-9">
                            <h4 class='suggestion-title' style="margin-top: 20px;">${suggestion.value}</h4>
                        </div>
                    </div>
                `;

                return html;
            },

            onSelect: (suggestion) => {
                window.location.href = suggestion.data.url;
            }
        });
    }
}

document.addEventListener("DOMContentLoaded", function () {
    lang = $('html').attr('lang');

    alphabeticalBrands();
    searchBrands();
}, false);
